/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
const $ = require('jquery');

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './styles/global.scss';

// start the Stimulus application
import './bootstrap';

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');
require('mdb-ui-kit/css/mdb.min.css')
require('mdb-ui-kit/js/mdb.min.js')

// require('bootstrap/dist/css/bootstrap.min.css');
require('bootstrap-fonts-complete')

require('@strongdm/glyphicon')
require('@fortawesome/fontawesome-free/js/all.min.js')
require('@fortawesome/fontawesome-free/css/all.min.css')

document.oncontextmenu = function() {
    return false;
  }

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();

});
